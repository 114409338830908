import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../Context/CartContext";
import { articoli } from "./prova"; // Assuming articoli is imported correctly
import { Link } from "react-router-dom";
import Cart from "./Cart"; // Import Cart component
import { AnimatePresence, motion } from "framer-motion";
import CLITCH from "../../assets/images/CLITCH.png";
import FELPA_GLITCH from "../../assets/FELPA GLITCH.png";
import { MdKeyboardArrowUp } from "react-icons/md";
import Carrello from "../../assets/SVG/Carrello";
import { FiAlertCircle, FiX } from "react-icons/fi";

function Store() {
    const { addToCart, cartItems } = useContext(CartContext);
    const [isVisible, setIsVisible] = useState(true);
    const [isContentVisible, setContentVisible] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [over400, setOver400] = useState(true);
    const [size, setSize] = useState(0);
    const [imgCount, setImgCount] = useState(0);
    const [imgN, setImgN] = useState(0);
    const [showNotification, setShowNotification] = useState(false);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const shouldHide = currentScrollY > 200;
        const shouldScroll = currentScrollY > 400;

        if (currentScrollY > lastScrollY) {
            // Scrolling down
            setIsVisible(!shouldHide);
            if (!shouldHide) setContentVisible(true);
        } else {
            // Scrolling up
            setIsVisible(true);
            if (currentScrollY <= 200) setContentVisible(false); // Adjust the threshold as needed
        }
        if (currentScrollY > lastScrollY) {
            // Scrolling down
            setOver400(!shouldScroll);
            //if (!shouldHide) setContentVisible(true);
        } else {
            // Scrolling up
            setOver400(true);
            //if (currentScrollY <= 200) setContentVisible(false); // Adjust the threshold as needed
        }

        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    });

    // JavaScript to handle increment and decrement
    function updateValue(change) {
        const input = document.getElementById("quantity");
        let currentValue = parseInt(input.value);
        currentValue += change;
        if (
            currentValue >= parseInt(input.min) &&
            currentValue <= parseInt(input.max)
        ) {
            input.value = currentValue;
        }
    }

    const handleSizeChange = (event) => {
        const value = event.target.value;
        if (value === "S") {
            setSize(0);
        } else if (value === "M") {
            setSize(1);
        } else if (value === "L") {
            setSize(2);
        }
    };

    const handleHover = (segno) => {
        if (segno === "+") {
            if (imgCount === imgN - 1) {
                setImgCount(0);
            } else {
                setImgCount(imgCount + 1);
            }
        } else {
            if (imgCount === 0) {
                setImgCount(imgN - 1);
            } else {
                setImgCount(imgCount - 1);
            }
        }
    };

    return (
        <div className="md:pt-14 md:min-h-[200vh] min-h-[100vh] text-bianco md:px-44 flex flex-col">
            <motion.div className="flex flex-col items-center justify-center w-full h-[100dvh]">
                <motion.div
                    initial={{ x: -1000 }}
                    animate={{ x: !isContentVisible ? 0 : -1000 }} // Move off-screen to the left when not visible
                    transition={{
                        type: "spring",
                        stiffness: 50, // Control the stiffness of the spring
                        damping: 10, // Control the damping (less damping = more bounciness)
                        mass: 1, // Control the mass of the element (affects the duration of the animation)
                    }}
                    className="self-start z-50 md:block hidden"
                >
                    <h2 className="text-4xl">La nostra</h2>
                    <h1 className="text-6xl uppercase mb-4 font-semibold">
                        collection
                    </h1>
                </motion.div>
                <motion.div
                    initial={{ x: -1000 }}
                    animate={{ x: 0 }}
                    transition={{
                        type: "spring",
                        stiffness: 50, // Control the stiffness of the spring
                        damping: 10, // Control the damping (less damping = more bounciness)
                        mass: 1, // Control the mass of the element (affects the duration of the animation)
                    }}
                    className="z-50 md:hidden -mt-8 w-full flex flex-col justify-center px-4"
                >
                    <h2 className="text-4xl">La nostra</h2>
                    <h1 className="text-5xl uppercase mb-4 font-semibold">
                        collection
                    </h1>
                </motion.div>

                <motion.div
                    initial={{ y: 1000 }}
                    animate={{ y: !isContentVisible ? 0 : -1000 }} // Move off-screen to the left when not visible
                    transition={{
                        type: "spring",
                        stiffness: 50,
                        damping: 10,
                        mass: 1,
                    }}
                    className="self-center absolute z-50 hidden md:block"
                >
                    <div className="relative">
                        <img src={CLITCH} alt="CLITCH" />
                        <motion.img
                            animate={{
                                y: ["0%", "-5%", "0%"], // Bounce movement
                                transition: {
                                    y: {
                                        duration: 4, // Duration of one bounce cycle
                                        ease: "easeInOut", // Easing function for the bounce
                                        repeat: Infinity, // Repeat the animation indefinitely
                                        repeatType: "loop", // Type of repetition
                                    },
                                },
                            }}
                            className="absolute top-[13%] -translate-y-1/2 left-[29%] translate-x-1/2 w-[440px] h-[440px] cursor-pointer"
                            src={FELPA_GLITCH}
                            alt="CLITCH"
                        />
                    </div>
                </motion.div>

                <div className="md:hidden w-[800px] h-[350px] flex justify-center items-center">
                    <img
                        className={``}
                        src={articoli[0].images[imgCount]}
                        alt="Felpa"
                    />
                </div>
                <motion.div
                    initial={{ x: 0, y: 0 }}
                    animate={{
                        x: !isContentVisible ? 0 : -300,
                        y: !over400 ? -200 : 0,
                    }} // Move slightly to the left when not visible
                    transition={{
                        type: "spring",
                        stiffness: 50,
                        damping: 10,
                        mass: 1,
                    }}
                    className="self-center fixed hidden md:block"
                >
                    <img
                        className={`${
                            isContentVisible ? "h-[700px]" : "h-[1000px]"
                        } w-[10000px] transition-all duration-700 object-contain`}
                        src={articoli[0].images[imgCount]}
                        alt="Felpa"
                    />
                </motion.div>

                <motion.div
                    initial={{ x: 1000 }}
                    animate={{ x: !isContentVisible ? 0 : 1000 }} // Move off-screen to the right when not visible
                    transition={{
                        type: "spring",
                        stiffness: 50,
                        damping: 10,
                        mass: 1,
                    }}
                    className="self-end md:flex gap-4  items-baseline z-50 mt-96 hidden"
                >
                    <h3 className="md:text-8xl text-7xl font-bold">01</h3>
                    <h3 className="md:text-5xl text-4xl uppercase mb-4">
                        CHIMERA HOODIE
                    </h3>
                </motion.div>
                <motion.div
                    initial={{ x: 1000 }}
                    animate={{ x: 0 }} // Move off-screen to the right when not visible
                    transition={{
                        type: "spring",
                        stiffness: 50,
                        damping: 10,
                        mass: 1,
                    }}
                    className="flex gap-2 items-baseline z-50  md:hidden"
                >
                    <h3 className="text-4xl font-bold">01</h3>
                    <nobr className="text-3xl uppercase mb-4">
                        CHIMERA HOODIE
                    </nobr>
                </motion.div>
            </motion.div>

            <motion.div
                className=" w-[100vw] my-12 flex flex-col justify-around gap-2 px-4 h-[70lvh] md:hidden"
                initial={{ x: 0 }}
                animate={{ x: 0 }} // Move in from the right when the content becomes visible
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    mass: 1,
                }}
            >
                <h2 className="text-6xl font-bold">O1 - HOODIE</h2>
                <h3 className="text-xl font-semibold">Black</h3>
                <p className=" font-thin">
                    La Felpa Chimera non è solo un capo d'abbigliamento, è il
                    simbolo di una community in continua crescita. Rappresenta
                    il nostro modo di connetterci, di unirci come individui che
                    condividono valori, visioni e obiettivi comuni. Indossare la
                    Felpa Chimera significa fare parte di qualcosa di più
                    grande: un movimento, una famiglia in cui l'unione e la
                    collaborazione sono al centro di tutto.
                </p>
                <div className="grid grid-cols-5 gap-3 ">
                    {articoli[0].images.map((image, index) => (
                        <img
                            key={index}
                            className={"w-28 h-28 object-cover"}
                            onClick={() => setImgCount(index)}
                            src={image}
                            alt="Hoodie"
                        />
                    ))}
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-3xl">€ 54</p>
                    <div className="flex gap-1 items-baseline ">
                        <label htmlFor="quantity" className="text-xl">
                            pz
                        </label>

                        <div className="flex">
                            <input
                                className="w-14 h-8 text-nero bg-bianco px-1 appearance-none focus:ring-0 focus:outline-none"
                                type="number"
                                name="quantity"
                                id="quantity"
                                placeholder="pz"
                                defaultValue={1}
                                max={10}
                                min={1}
                            />
                            <div className="flex flex-col justify-between items-center h-8 w-6 bg-bianco text-nero border-l border-nero">
                                <button
                                    className=""
                                    onClick={() => updateValue(1)}
                                >
                                    <MdKeyboardArrowUp />
                                </button>
                                <button
                                    className="rotate-180"
                                    onClick={() => updateValue(-1)}
                                >
                                    <MdKeyboardArrowUp />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center gap-2">
                    <select
                        name="size"
                        id="size"
                        className="w-[50%] h-10 bg-[#dedede] text-azzurro text-center text-xl font-semibold focus:ring-0 focus:outline-none rounded-none"
                    >
                        <option className="appearance-none" value="S">
                            Small
                        </option>
                        <option className="appearance-none" value="M">
                            Medium
                        </option>
                        <option className="appearance-none" value="L">
                            Large
                        </option>
                    </select>
                    <button
                        onClick={() => addToCart(articoli[0])}
                        className="border border-bianco bg-nero text-bianco w-[50%] h-10 flex items-center justify-center gap-2 "
                    >
                        <span className="text-xl">Aggiungi al</span>{" "}
                        <Carrello className={"w-5"} />
                    </button>
                </div>
            </motion.div>
            <motion.div
                className="h-[500px] fixed ml-[38%] mt-[20vh] hidden md:block"
                initial={{ x: 1000 }}
                animate={{
                    x: isContentVisible ? 0 : 1000,
                    y: !over400 ? -200 : 0,
                }} // Move in from the right when the content becomes visible
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    mass: 1,
                }}
                style={{
                    opacity: isContentVisible ? 1 : 0, // Adjust opacity based on visibility
                    transition: "opacity 0.5s", // Smooth opacity transition
                }}
            >
                <div className="flex flex-col justify-between h-full max-w-[30vw] ">
                    <h2 className="text-6xl">O1 - HOODIE</h2>
                    <h3 className="text-xl">Black</h3>
                    <p className=" font-thin">
                        La Felpa Chimera non è solo un capo d'abbigliamento, è
                        il simbolo di una community in continua crescita.
                        Rappresenta il nostro modo di connetterci, di unirci
                        come individui che condividono valori, visioni e
                        obiettivi comuni. Indossare la Felpa Chimera significa
                        fare parte di qualcosa di più grande: un movimento, una
                        famiglia in cui l'unione e la collaborazione sono al
                        centro di tutto.
                    </p>
                    <div className="grid grid-cols-5 gap-3">
                        {articoli[0].images.map((image, index) => (
                            <img
                                key={index}
                                className={
                                    "w-32 h-32 object-cover cursor-pointer"
                                }
                                onClick={() => setImgCount(index)}
                                src={image}
                                alt="Hoodie"
                            />
                        ))}
                    </div>
                    <div className="flex justify-between items-center">
                        <p className="text-3xl">€ 54</p>
                        <div className="flex gap-1 items-baseline ">
                            <label htmlFor="quantity" className="text-xl">
                                pz
                            </label>

                            <div className="flex">
                                <input
                                    className="w-14 h-8 text-nero bg-bianco px-1 appearance-none focus:ring-0 focus:outline-none"
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    placeholder="pz"
                                    defaultValue={1}
                                    max={10}
                                    min={1}
                                />
                                <div className="flex flex-col justify-between items-center h-8 w-6 bg-bianco text-nero border-l border-nero">
                                    <button
                                        className=""
                                        onClick={() => updateValue(1)}
                                    >
                                        <MdKeyboardArrowUp />
                                    </button>
                                    <button
                                        className="rotate-180"
                                        onClick={() => updateValue(-1)}
                                    >
                                        <MdKeyboardArrowUp />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center gap-2">
                        <select
                            name="size"
                            id="size"
                            className="w-[50%] h-10 bg-[#dedede] text-azzurro text-center text-xl font-semibold focus:ring-0 focus:outline-none rounded-none"
                            onChange={handleSizeChange}
                            value={size === 0 ? "S" : size === 1 ? "M" : "L"}
                        >
                            <option className="appearance-none" value="S">
                                Small
                            </option>
                            <option className="appearance-none" value="M">
                                Medium
                            </option>
                            <option className="appearance-none" value="L">
                                Large
                            </option>
                        </select>
                        <button
                            onClick={() => {
                                addToCart(articoli[size]);
                                setShowNotification(true);
                            }}
                            className="border border-bianco bg-nero text-bianco w-[50%] h-10 flex items-center justify-center gap-2 "
                        >
                            <span className="text-xl">Aggiungi al</span>{" "}
                            <Carrello className={"w-5"} />
                        </button>
                    </div>
                </div>
            </motion.div>

            {showNotification && (
                <Notification
                    text={"Aggiunto al carrello!"}
                    id={1}
                    removeNotif={() => setShowNotification(false)}
                />
            )}
        </div>
    );
}

export default Store;

const Notification = ({ text, id, removeNotif }) => {
    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            removeNotif();
        }, 3000);

        return () => clearTimeout(timeoutRef);
    }, []);

    return (
        <motion.div
            layout
            initial={{ y: 15, scale: 0.9, opacity: 0 }}
            animate={{ y: 0, scale: 1, opacity: 1 }}
            exit={{ y: -25, scale: 0.9, opacity: 0 }}
            transition={{ type: "spring" }}
            className="p-4 w-80 flex items-start rounded-lg gap-2 text-lg font-medium shadow-lg text-bianco bg-azzurro fixed z-50 bottom-4 right-4"
        >
            <FiAlertCircle className="text-3xl absolute -top-4 -left-4 p-2 rounded-full bg-bianco text-azzurro shadow" />
            <span>{text}</span>
            <button onClick={() => removeNotif(id)} className="ml-auto mt-0.5">
                <FiX />
            </button>
        </motion.div>
    );
};
